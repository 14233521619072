<template>
  <div class="item" @click="gotoPath">
    <div class="card">
      <div class="info">
        <el-image class="img" :src="product.image"></el-image>
        <!--        <img class="img" :src="product.image">-->
        <div class="text">
          <div class="time">{{ delBtn ? '咨询' : '浏览' }}时间：{{ product.add_time |moments }}</div>
          <div class="name" @click.stop=""><span>{{ product.game_name }}</span>{{ product.name }}</div>
          <div class="sign">
            <img src="@/assets/image/AllGame/isSign.png" alt="">
            <img v-if="product.bind_psn === 1" src="@/assets/image/AllGame/bindPsn.png" alt="">
            <img v-if="product.bind_tap === 1" src="@/assets/image/AllGame/bindTap.png" alt="">
          </div>

          <div class="other">
            <div class="zone">游戏区服：<span v-for="(item,index) in product.category" :key="index">{{ item.value }}<span
              v-if="index !=  product.category.length-1">-</span> </span></div>
            <div class="hot">热度：{{ product.click }}</div>
          </div>
        </div>
      </div>
      <div class="price">¥{{ product.price }}</div>

      <div class="btnGroup" v-if="delBtn">
<!--        <div class="infoBtn" target="_blank"  @click.stop="cusSerClick">继续咨询</div>-->
        <div class="delBtn" @click.stop="delClick(product.id)">删除记录</div>
      </div>
      <div class="btnGroup" v-else>
        <div class="infoBtn" v-if="infoBtn" @click.stop="gotoPath">查看详情</div>
<!--        <a class="cusSerBtn" v-if="cusSerBtn" target="_blank" @click.stop="cusSerClick">咨询客服</a>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "historyItem",
  props: {
    product: {
      type: Object,
      default: null
    },
    infoBtn: {
      type: Boolean,
      default: false,
    },
    cusSerBtn: {
      type: Boolean,
      default: false,
    },
    delBtn: {
      type: Boolean,
      default: false,
    },
    delClick: {
      type: Function,
      default: null
    },
  },
  methods: {
    /**
     * 跳转商品详情页面
     */
    gotoPath() {
      let name = this.product.product_type === 1 ? 'GameDetails' : 'EquipmentDetails'
      this.$router.push({
        name: name,
        query: {
          game_id: this.product.game_id,
          id: this.product.product_id
        }
      });
    },
    /**
     * 联系客服
     */
    cusSerClick(){
      if(this.product.game_id == 10){
        let routeData = this.$router.resolve({
          name: 'CustomerSer',
          query: {
            game_id: this.product.game_id
          }
        })
        window.open(routeData.href, '_blank')
      } else {
        this.$accessLink(this.product.buy_customer)
        var api = $53.createApi()
        api.push('cmd', 'comeinfo');
        api.push('title', this.product.name);
        api.push('logo', this.product.image);
        api.push('content', `商品价格:${this.product.price}`);
        api.push('curl',`https://www.pxb7.com/gamedetails?game_id=${this.product.game_id}&id=${this.product.product_id}`);
        api.query();
        api.push('cmd', 'kfclient');
        api.push('type', 'new');
        api.push('group_id', this.product.buy_group);
        api.query();
      }

    }
  }
}
</script>

<style lang="less" scoped>
.item {

  cursor: pointer;

  .card {
    padding: 20px 0;
    margin: 0 27px 0 24px;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .img {
        width: 150px;
        height: 150px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
        margin: 0 20px 0 0;
        border-radius: 10px;
      }

      .text {
        width: 345px;

        .time {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #8c8c8c;
          padding: 0 0 8px;
          border-bottom: 1px solid #dfdfdf;;
        }

        .name {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #000000;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-align: justify;
          text-justify: newspaper;
          word-break: break-all;
          margin: 13px 0 6px;

          span {
            display: inline-block;
            font-size: 14px;
            color: #fcad13;
            text-align: CENTER;
            padding: 1px 8px;
            border: 1px solid #fcad13;
            border-radius: 15px;
            margin: 0 10px 0 0;
          }
        }

        .sign {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 24px;
          margin: 0 0 8px;

          img {
            height: 20px;
            margin: 0 10px 0 0;
          }
        }

        .other {
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #8c8c8c;
          }

          .zone {
            width: 213px;
            text-align: justify;
            text-justify: newspaper;
            word-break: break-all;
          }

          .hot {
            width: 95px;
            text-align: right;
          }
        }
      }
    }

    .price {
      width: 119px;
      margin: 0 64px 0 74px;
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #fc4a00;
    }

    .btnGroup {
      div, a {
        display: block;
        width: 128px;
        height: 35px;
        line-height: 35px;
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #000000;
        cursor: pointer;
      }

      .infoBtn {
        background: #ffdb51;
        border: 1px solid #ffdb51;
        margin: 0 0 5px;
      }

      .cusSerBtn {
        border: 1px solid #000000;
      }

      .delBtn {
        background: #f2f2f2;
        border: 1px solid #f2f2f2;
        color: #C5C5C5;
      }
    }
  }


}

.item:hover {
  background: #fffcf2;
}

</style>
