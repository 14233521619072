<template>
  <div class="accHistory">
    <div class="header">
      <div class="left">
        <img src="@/assets/image/PersonalCenter/AccHistory/headerIcon.png" alt="">
        咨询记录
        <span>
          （最近50条）
        </span>
      </div>
      <div class="right">
        <el-input placeholder="请输入关键词进行搜索" v-model="search" class="searchInput">
          <img slot="suffix"  class="searchIcon" src="../../../assets/image/AllGame/searchIcon.png">
        </el-input>
      </div>

    </div>
    <div class="list" v-if="totalCount !== 0">
      <history-item  v-for="(item,index) in search? searchList(search,historyList):searchList(search,historyList).slice((currentPage-1)*PageSize , currentPage*PageSize)" :key="index"
                     :product="item" del-btn info-btn :del-click="delHistory"></history-item>
      <div class="pages" v-if="!search">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="PageSize"
          layout="prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
    <div class="empty" v-else>
      <img src="@/assets/image/PersonalCenter/MyCollection/noData.png" alt="">
    </div>

    <!--  删除记录确认框  -->
    <el-dialog
      :visible.sync="delBtnConfrim"
      custom-class="delBtnConfrim"
      :show-close="false"
      width="358px"
      top="35vh"
      center>
      <div id="delBtnConfrim">
        <div class="title">提示</div>
        <div class="text">
          删除记录后将无法恢复<br/>
          是否确认删除？
        </div>
        <div class="btn" @click="historyCusDel">确认删除</div>
        <img class="close" src="../../../assets/image/AccountDeal/close.png" alt="" @click="delBtnConfrim = false">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {apiHistoryCusList,apiHistoryCusDel} from "@/request/API";
import historyItem from "@/views/PersonalCenter/History/historyItem";

export default {
  name: 'accHistory',
  props: {
  },
  components: {
    historyItem
  },
  data () {
    return {
      // 默认显示第几页
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:0,
      // 默认每页显示的条数（可修改）
      PageSize:5,
      // 浏览历史列表
      historyList:[],
      //搜索关键字
      search:'',
      //删除记录确认框
      delBtnConfrim:false,
      //选中要删除的记录id
      selectId:''
    }
  },
  methods: {
    /**
     * 分页监听事件
     * @param val 当前页码
     */
    handleCurrentChange(val) {
      document.body.scrollTop = document.documentElement.scrollTop = 157;
    },
    /**
     * 获取历史浏览列表
     */
    getHistoryList(){
      apiHistoryCusList({
      }).then(res=>{
        this.totalCount = res.data.length
        this.historyList = res.data
      })
    },

    /**
     * 步骤一 搜索游戏名称
     * @param indexKey 关键词
     * @param list 数组
     */
    searchList(indexKey, list) {
      if (indexKey) {
        var reg = new RegExp(indexKey, 'ig')
        return list.filter(function (e) {
          //匹配所有字段
          return Object.keys(e).some(function (key) {
            if (typeof e[key] == 'string') {
              return e[key].match(reg);
            }
          })
          this.$forceUpdate()
        })
      }
      return list;
    },

    /**
     * 删除记录
     */
    delHistory(id){
      this.selectId = id
      this.delBtnConfrim = true
    },

    historyCusDel(id){
      apiHistoryCusDel({
        id:this.selectId
      }).then(res=>{
        this.$message({
          type:"success",
          center:true,
          message:"删除成功"
        })
        this.delBtnConfrim = false
        this.getHistoryList()
      })
    }
  },
  mounted () {
    this.getHistoryList()
  },
  watch: {

  },
  computed: {

  }
}
</script>


<style lang="less">
.delBtnConfrim{
  border-radius: 10px;
  .el-dialog__header , .el-dialog__body{
    padding: 0 !important;
  }
  #delBtnConfrim{
    position: relative;
    padding: 26px 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #353535;
    }
    .text{
      margin: 22px 0;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #696969;
      text-align: center;
    }
    .btn{
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: CENTER;
      color: #000000;
      background: #ffdb51;
      border-radius: 21px;
      cursor: pointer;
    }
    .close{
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: -40px;
      left: calc(50% - 15px);
      cursor: pointer;
    }
  }
}
</style>

<style scoped lang="less">
.accHistory{
  height: 100%;
  background: linear-gradient(
    180deg, #f0f0f0, #FFFFFF 3%);
  .header{
    height: 80px;
    border-radius: 20px 20px 0 0;
    background: linear-gradient(93deg,#6f7984, #283748 100%);
    padding:0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div{
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: RIGHT;
      color: #ffffff;
    }
    .left{
      display: flex;
      align-items: center;
      img{
        margin: 0 9px 0 0;
      }
      span{
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .right{
      .searchInput{
        /deep/.el-input__inner{
          width: 350px;
          height: 50px;
          background: #ffffff;
          border: 2px solid #283748;
          border-radius: 28px;
          padding: 0 50px 0 30px;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Light;
          font-weight: 300;
          color: #5B5B5B;
        }
        /deep/.el-input__inner::-webkit-input-placeholder{
          color: #949494;
        }
        /deep/.el-input__suffix{
          display: flex;
          align-items: center;
          right: 20px;
        }
      }

    }
  }
  .list{
    background: #FFFFFF;
    .pages{
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .empty{
    width: 100%;
    height: calc(100% - 60px) ;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 196px;
      height: 151px;
      object-fit: cover;
    }
  }
}

</style>
